.share{
    padding: 50px 90px;
}
.share .headingOne {    
    font-size: 15pt;
    color: #2d3e51;
}
.share .afterNav {
    background-image: url(/static/media/bg.99698e94.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    padding: 30px 148px 53px 148px;
}
.share img.shareLogo{
    width: 187px;
    margin: auto;
        margin-bottom: auto;
    display: block;
    margin-bottom: 48px;
}
.share img.award {
    width: 94px;
    float: right;
    margin-top: -73px;
    margin-right: -100px;
}
.share .push33 {
    margin-top: 64px;
}
.share h6,.share h5, .share h1 {
    margin-top: 24px;
}
.share a.ios {
    background: white !important;
    color: #00AD8A !important;
    border: solid 1px;
}
.share .push1 {
    margin-top: -54px;
}
.share .push2 {
    margin-top: 29px;
}
.share .myChart svg {
    width: 50%;
    margin: auto;
    display: block;
}

.share img.dot {
    margin-right: 5px;
    margin-top: -1px;
}
.share img.dot1{
    width: 25px;
    height: 25px;
}
.share .push3 {
    margin-top: 50px;
}
.share .title{
    font-size: 18px;
}
.share .title2 {
    margin-bottom: 40px;
}
.share .scoreSection {
    padding: 9px 75px;
}

@media screen and (max-width: 768px) {
    .share {
        padding: 0px 20px;
    }
   .share .afterNav {     
        /* background-size: contain; */
        padding: 10px 20px;        
    }
    .share img.award {
        width: 55px;      
        margin-top: -73px;
        margin-right: -11px;
    }
    .share .push1 {
        margin-top: 1px;
    }
    .share h6, .share h5 {
        margin-top: 10px;
    }
    .share .push2 {
        margin-top: 18px;
    }
    .share a.android,  .share a.ios {      
        margin-bottom: 20px;
    }
    .share .push3 {
        margin-top: 20px;
    }
    .share .title2 {
        margin-bottom: 28px;
        margin-top: 30px;
    }
    .share .scoreSection {
        padding: 9px 0px;
    }
   
}