.login img.logo {
    width: 47%;
    margin: auto;
        margin-top: auto;
    display: block;  
}
.login .p1{
    font-family: font2;
    font-size: 24px;
    color: #2d3e51;
    text-decoration: rgb(45, 62, 81);
    text-align: center;
    margin-top: 40px;
}
.login a.forgotPassword {
    position: absolute;
    top: 0px;
    right: 30px;
    top: 13px;
    font-size: 13px;
}
.login .p2 {
    font-size: 17px;
    color: #2d3e51;
    text-decoration: rgb(45, 62, 81);
    line-height: 24px;
    margin-top: 29px;  
    margin-bottom: 35px;
}
.login input[type="submit"] {
    background: #2d3e51;
    color: white;
    border: none;
    margin-top: 23px;
    font-family: font2;
}
.login .push {
    margin-top: 10px;
}
.login span {
    display: block;
    text-align: center;
    border-radius: 12px;
    width: 100%;
    padding: 9px 13px;
    border: solid 0.5px gray;
    margin-bottom: 30px;
}
.login span img {
    margin-right: 4px;
    width: 23px;
    margin-top: -5px;
}
@media screen and (max-width: 768px) {
    .login img.logo {
        width: 54%; 
    }
    .login .p1 {       
        font-size: 20px;     
        margin-top: 21px;
    }
    .login .p2 {
        font-size: 15px;       
        margin-top: 17px;
        margin-bottom: 29px;
        text-align: center;
    } 
    .login span {
        padding: 6px 1px;        
        margin-bottom: 21px;
    }
    .login a.forgotPassword {
        right: 20px;
        top: 11px;
    }
  
}