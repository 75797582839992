.signUp img.logo {
    width: 47%;
    margin: auto;
        margin-top: auto;
    display: block; 
}
.signUp .p1{
    font-family: font2;
    font-size: 24px;
    color: #2d3e51;
    text-decoration: rgb(45, 62, 81);
    text-align: center;
    margin-top: 40px;
}
.signUp .p2 {
    font-size: 17px;
    color: #2d3e51;
    text-decoration: rgb(45, 62, 81);
    line-height: 24px;
    margin-top: 29px;  
    margin-bottom: 35px;
}
.signUp input[type="submit"] {
    background: #2bb58e;
    color: white;
    border: none;
    margin-top: 23px;
    font-family: font2;
}
.signUp .push {
    margin-top: 10px;
}
.signUp span {
    display: block;
    text-align: center;
    border-radius: 12px;
    width: 100%;
    padding: 9px 13px;
    border: solid 0.5px gray;
    margin-bottom: 30px;
}
.signUp .danceEmoji {
    margin: auto;
    display: block;
    width: 14%;
}
.signUp .ballonEmoji1 {
    position: absolute;
    top: -66px;
    z-index: 0;
    left: 50%;
    transform: translate(-50%, 0%);
}
.signUp span img{
    margin-right: 4px;
    width: 23px;
    margin-top: -5px;
}
@media screen and (max-width: 768px) {
    .signUp img.logo {
        width: 54%;
    }
    .signUp .p1 {       
        font-size: 20px;     
        margin-top: 21px;
    }
    .signUp .p2 {
        font-size: 15px;       
        margin-top: 17px;
        margin-bottom: 29px;
        text-align: center;
    } 
    .signUp span {
        padding: 6px 1px;        
        margin-bottom: 21px;
    }
  
}