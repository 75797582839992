.forgotPassword img.logo {
    width: 47%;
    margin: auto;
        margin-top: auto;
    display: block;
}
.forgotPassword .p1{
    font-family: font2;
    font-size: 24px;
    color: #2d3e51;
    text-decoration: rgb(45, 62, 81);
    text-align: center;
    margin-top: 40px;
}
.forgotPassword a.forgotPassword {
    position: absolute;
    top: 0px;
    right: 30px;
    top: 10px;
    font-size: 13px;
}
.forgotPassword .p2 {
    font-size: 17px;
    color: #2d3e51;
    text-decoration: rgb(45, 62, 81);
    line-height: 24px;
    margin-top: 29px;  
    margin-bottom: 35px;
}
.forgotPassword input[type="submit"] {
    background: #2d3e51;
    color: white;
    border: none;
    margin-top: 23px;
    font-family: font2;
}
.forgotPassword .push {
    margin-top: 50px;
}

@media screen and (max-width: 768px) {
    .forgotPassword img.logo {
        width: 54%;
    }
    .forgotPassword .p1 {       
        font-size: 20px;     
        margin-top: 21px;
    }
    .forgotPassword .p2 {
        font-size: 15px;       
        margin-top: 17px;
        margin-bottom: 29px;
        text-align: center;
    }     
  
}