@font-face {
  font-family: 'font1';
  src: url('./assets/fonts/Quicksand-Medium.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'font2';
  src: url('./assets/fonts/Quicksand-SemiBold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'font3';
  src: url('./assets/fonts/quicksand.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

html, body{
  font-family: font1;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.fullWidth {
  width: 100%;
}
.changePasswordPush {
  margin-top: 40px;
}
.grey {
  color: #878787;
}
.facebook button, .google button{
  padding: 0;
  background: white;
  border: 0;
  width: 100%;
}
.alert {
  text-align: center;
}
.right {
  text-align: right;
}
.arrow::after{
  content: "▼";
  margin-left: 5px;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.centerImage {
  margin: auto;
  display: block;
}
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
*, *:hover, *:active, *:visited {
  outline:none !important;
}
a {
  color:inherit !important;
  text-decoration: none;
  background-color: inherit !important;
}
a:hover {
  text-decoration: none !important;
}
.off{
  display: none;
}
.on{
  display: block;
}
.hidden{
  visibility: hidden;
}
.push333 {
  margin-bottom: 12px;
}
.push333 input {
  width: 100%;
  padding: 8px;
  margin-top: 17px;
}
.bold{
  font-weight: bold;
}
.boldFont{
  font-family: font2;
}
.boldFont1{
  font-family: font3;
}
.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 8888;
}
.loading-indicator:after {
  content: 'EXAMBLY.COM...';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 8889;
  color:white;
  text-align:center;
  font-weight:bold;
  font-size:1.5rem; 
  font-family:font2       
}
.green{
  color: #2bb58e; 
}
.modal-header { 
  z-index: 2;
}
.moveToFront{
  position: relative;
  z-index: 2;
}
.blue{
  color: #2d3e51;
}
.attempted{
  background: #2bb58e !important; 
}
.skipped{
  background: #fabf61 !important;
}
.slick-slider {
  width: 100% !important;
}
.slick-prev::before, .slick-next::before { 
  font-size: 24px; 
  opacity: .75;
  color: #eaebed;
}
.pending{
  background: white !important;
  color:#2bb58e !important; 
  box-shadow: 0pt 0.5pt 4pt rgba(0,0,0,0.1);
}
.incorrect{
  background: #e36b6b !important;
}
.padOff{
  padding: 0px !important;
}
.paddingLeftOff{
  padding-left: 0px !important;
}
.paddingRightOff{
  padding-right: 0px !important;
}
.rs-play, .rs-play:active { 
  background: none !important;
  margin-left: 10px;
}
.mobileOnly{
  display: none !important; 
}
.desktopOnly{
  display: block !important; 
}
input[type="text"], input[type="password"], input[type="email"], input[type="submit"]{
  border-radius: 12px;
  width: 100%;
  padding: 11px 13px;
  border: solid 0.5px #afbdcc;
  margin-bottom: 12px;
}
.login, .signUp, .forgotPassword {
  padding: 1px 27px;
}
.modal-header h5 {
  text-align: right !important;
  width: 100%;

}

@media screen and (max-width: 768px) {
  .mobileOnly{
    display: block !important; 
  }
  .desktopOnly{
    display: none !important; 
  }
  input[type="text"], input[type="password"], input[type="email"], input[type="submit"] {
    border-radius: 12px;  
    padding: 9px 10px;
  }
  .login, .signUp, .forgotPassword {
    padding: 1px 7px;
  }
 

}


